import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { getThemeStyles } from 'holded/lib/styles';
import { PhotoSection } from 'holded/modules/cms/domain/components/photo';

import { defaultThemeValues, ImageTheme } from './ImageTheme';

const Image = dynamic(() => import('holded/modules/cms/ui/shared/components/Image'));
const Video = dynamic(() => import('holded/modules/cms/ui/shared/components/Video'));

const WIDTH: Record<string, string> = {
  width50: 'w-1/2',
  width66: 'w-2/3',
  fullWidth: 'w-full',
};

const ImageSection = ({ image, imageMobile, position, width, videoHref, background, lazyLoad }: PhotoSection) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);
  const positionClass =
    position === 'center'
      ? 'justify-center'
      : position === 'left'
      ? 'justify-start'
      : position === 'right'
      ? 'justify-end'
      : '';

  useEffect(() => {
    const themeStyles = getThemeStyles(ImageTheme.photoSection, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <div className={themeValues.background}>
      {videoHref ? (
        <div className={'max-w-3xl mx-auto'}>
          <Video url={videoHref} />
        </div>
      ) : (
        <>
          <div className={`lg:max-w-7xl mx-auto flex ${positionClass}`}>
            {image && (
              <div className={`hidden md:flex ${positionClass} md:${WIDTH[width]} h-auto`}>
                <Image
                  media={image?.data}
                  classImg={`object-cover rounded-lg`}
                  lazy={lazyLoad ? !!lazyLoad?.desktop : true}
                />
              </div>
            )}
          </div>
          {imageMobile?.data && (
            <Image
              media={imageMobile.data}
              classImg={`md:hidden object-cover`}
              lazy={lazyLoad ? !!lazyLoad?.mobile : true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ImageSection;
