import { sectionStyle } from 'holded/lib/styles';

export const defaultThemeValues = {
  background: '',
};

export const ImageTheme: PhotoSectionThemeType = {
  photoSection: {
    background: {
      light: sectionStyle.background.light,
      soft: sectionStyle.background.soft,
      dark: sectionStyle.background.dark,
    },
  },
};

export type PhotoSectionThemeType = {
  photoSection: {
    background: {
      light: string;
      soft: string;
      dark: string;
    };
  };
};
